import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
};
//internally using immer lib (can create mutable state)
export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    updateAllCategory: (state, action) => {
      state.categories = action.payload;
    },
  },
});
// this is for dispatch
export const { updateAllCategory } = categorySlice.actions;
// this is for configureStore
export default categorySlice.reducer;
