import Cookies from "js-cookie";
export const logout = (history) => {
  Cookies.remove("x_ufo");
  Cookies.remove("x_auth_token");
  return history("/");
};
export const getServiceName = (code)=>{
 const registrations = [
    { name: "Proprietorship Registration", code: "PRR" },
    { name: "Partnership Registration", code: "PAR" },
    { name: "Private Limited Company", code: "PLC" },
    { name: "One Person Company Registration", code: "OPCR" },
    { name: "Section 8 Company Registration", code: "SCR" },
    { name: "Limited Liability Partnership", code: "LLP" },
    { name: "Public Company Registration", code: "PCR" },
    { name: "Nidhi Company Registration", code: "NCR" },
    { name: "Trademark Registration", code: "TR" },
    { name: "PF Registration", code: "PFR" },
    { name: "ESI Registration", code: "ESI" },
    { name: "GST Registration", code: "GST" },
    { name: "GST Return Filing", code: "GRF" },
    { name: "GST Registration Cancellation", code: "GRC" },
    { name: "Personal Tax Filing", code: "PTF" },
    { name: "Business Tax Filing", code: "BTF" },
    { name: "Form 16", code: "F16" },
    { name: "Proprietorship", code: "PRO" },
    { name: "Partnership", code: "PTS" },
    { name: "Company", code: "COM" },
    { name: "LLP Compliance", code: "LLPC" }
];
    for (let registration of registrations) {
        if (registration.code === code) {
            return registration.name;
        }
    }
    return null;
}
