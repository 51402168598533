import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Package: [],
};

//internally using immer lib (can create mutable state)
export const packageSlice = createSlice({
  name: "packageData",
  initialState,
  reducers: {
    updateAllPackage: (state, action) => {
      state.Package = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllPackage } = packageSlice.actions;

// this is for configureStore
export default packageSlice.reducer;
