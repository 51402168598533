
import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class Coupon extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      // config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllCouponConfig = ApiRoutes.Coupon.All;
  AddCouponConfig = ApiRoutes.Coupon.AddCoupon;
  GetCouponByIdConfig = ApiRoutes.Coupon.GetCouponById;
  editCouponConfig = ApiRoutes.Coupon.EditCoupon;
  deleteCouponConfig = ApiRoutes.Coupon.DeleteCoupon;

  getAllCoupon = async () => {
    return this.instance({
      method: this.AllCouponConfig.Method,
      url: this.AllCouponConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getCouponById = async (data) => {
    return this.instance({
      method: this.GetCouponByIdConfig.Method,
      url: this.GetCouponByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };

  addCoupon = async (data) => {
    return this.instance({
      method: this.AddCouponConfig.Method,
      url: this.AddCouponConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  editCoupon = async (data) => {
    return this.instance({
      method: this.editCouponConfig.Method,
      url: this.editCouponConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  deleteCoupon = async (data) => {
    return this.instance({
      method: this.deleteCouponConfig.Method,
      url: this.deleteCouponConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
}

export default Coupon;
