
import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class User extends HttpClient {

  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllUserConfig = ApiRoutes.User.All;
  AddUserConfig = ApiRoutes.User.AddUser;
  editUserConfig = ApiRoutes.User.EditUser;
  deleteUserConfig = ApiRoutes.User.DeleteUsers;
  getUserByIdConfig = ApiRoutes.User.GetUserById;
  getAllUsersConfig = ApiRoutes.User.All;
  AddUserByIdConfig = ApiRoutes.User.AddUser;

  getAllUsers = async () => {
    return this.instance({
      method: this.getAllUsersConfig.Method,
      url: this.getAllUsersConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  AddUser = async () => {
    return this.instance({
      method: this.AddUserByIdConfig.Method,
      url: this.AddUserByIdConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  getAllUser = async () => {
    return this.instance({
      method: this.AllUserConfig.Method,
      url: this.AllUserConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  addUser = async (data) => {
    return this.instance({
      method: this.AddUserConfig.Method,
      url: this.AddUserConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  editUser = async (data) => {
    return this.instance({
      method: this.editUserConfig.Method,
      url: this.editUserConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  deleteUser = async (data) => {
    return this.instance({
      method: this.deleteUserConfig.Method,
      url: this.deleteUserConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  getUserById = async (data) => {
    return this.instance({
      method: this.getUserByIdConfig.Method,
      url: this.getUserByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
}

export default User;
