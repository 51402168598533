import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ITRPackage: [],
};

//internally using immer lib (can create mutable state)
export const itrPackageSlice = createSlice({
  name: "ITRPackageData",
  initialState,
  reducers: {
    updateAllITRPackage: (state, action) => {
      state.ITRPackage = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllITRPackage } = itrPackageSlice.actions;

// this is for configureStore
export default itrPackageSlice.reducer;
