export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/adminLogin",
      Method: HttpMethod.Post,
    },
  },

  User: {
    All: {
      Endpoint: "/getAllUser",
      Method: HttpMethod.Post,
    },
    DeleteUsers: {
      Endpoint: "/deleteUser",
      Method: HttpMethod.Post,
    },
    AddUser: {
      Endpoint: "/addUser",
      Method: HttpMethod.Post,
    },
    EditUser: {
      Endpoint: "/editUser",
      Method: HttpMethod.Post,
    },
    GetUserById: {
      Endpoint: "/getUserById",
      Method: HttpMethod.Post,
    },

    AddBanner: {
      Endpoint: "/registration",
      Method: HttpMethod.Post,
    },
    EditBanner: {
      Endpoint: "/banner/editBanner",
      Method: HttpMethod.Post,
    },
    DeleteBanner: {
      Endpoint: "/deleteUser",
      Method: HttpMethod.Post,
    },
    GetBannerById: {
      Endpoint: "/getUserById",
      Method: HttpMethod.Post,
    },
  },
  Package: {
    All: {
      Endpoint: "/package/getAllPackage",
      Method: HttpMethod.Post,
    },

    AddPackage: {
      Endpoint: "/createCoupon",
      Method: HttpMethod.Post,
    },
    EditPackage: {
      Endpoint: "/editCoupon",
      Method: HttpMethod.Post,
    },
    DeletePackage: {
      Endpoint: "/deleteCoupon",
      Method: HttpMethod.Post,
    },
    GetPackageById: {
      Endpoint: "/getCouponById",
      Method: HttpMethod.Post,
    },
  },
  Coupon: {
    All: {
      Endpoint: "/getAllCoupons",
      Method: HttpMethod.Post,
    },

    AddCoupon: {
      Endpoint: "/addCoupon",
      Method: HttpMethod.Post,
    },
    EditCoupon: {
      Endpoint: "/editCoupon",
      Method: HttpMethod.Post,
    },
    DeleteCoupon: {
      Endpoint: "/deleteCoupon",
      Method: HttpMethod.Post,
    },
    GetCouponById: {
      Endpoint: "/getCouponById",
      Method: HttpMethod.Post,
    },
  },
  Order: {
    All: {
      Endpoint: "/all-orders",
      Method: HttpMethod.Post,
    },
  },
  ITRPackage: {
    All: {
      Endpoint: "/itr/getAllPackage",
      Method: HttpMethod.Post,
    },

    AddPackage: {
      Endpoint: "/itr/addPackage",
      Method: HttpMethod.Post,
    },
    EditPackage: {
      Endpoint: "/itr/editPackage",
      Method: HttpMethod.Post,
    },
    DeletePackage: {
      Endpoint: "/itr/deletePackage",
      Method: HttpMethod.Post,
    },
    GetPackageById: {
      Endpoint: "/itr/getPackageById",
      Method: HttpMethod.Post,
    },
  },
  ServiceBanner: {
    All: {
      Endpoint: "/serviceBanner/getAllServiceBanner",
      Method: HttpMethod.Post,
    },

    AddServiceBanner: {
      Endpoint: "/serviceBanner/addServiceBanner",
      Method: HttpMethod.Post,
    },
    EditServiceBanner: {
      Endpoint: "/serviceBanner/editServiceBanner",
      Method: HttpMethod.Post,
    },
    DeleteServiceBanner: {
      Endpoint: "/serviceBanner/deleteServiceBanner",
      Method: HttpMethod.Post,
    },
    GetBannerServiceById: {
      Endpoint: "/serviceBanner/getServiceBannerById",
      Method: HttpMethod.Post,
    },
  },
  Enquiry: {
    All: {
      Endpoint: "/enquiry/getAllEnquiry",
      Method: HttpMethod.Post,
    },
    GetAllEarnedPoints: {
      Endpoint: "/auth/getAllEarnedPoints",
      Method: HttpMethod.Post,
    },
  },
  Form16: {
    All: {
      Endpoint: "/form16/getAllForm16",
      Method: HttpMethod.Post,
    },
  },
  ContactUs: {
    contact: {
      Endpoint: "/contact/getAllContact",
      Method: HttpMethod.Post,
    },
  },
  OrderDetail: {
    order: {
      Endpoint: "/order/getAllOrder",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
