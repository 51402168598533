// assets
import {
  IconArticle,
  IconMap2,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconPackages,
  IconPictureInPicture,
  IconIdBadge2,
  IconIdBadge,
  IconUserCircle,
  IconCreditCard,
} from "@tabler/icons";

// constant
const icons = {
  IconMap2,
  IconArticle,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconPackages,
  IconPictureInPicture,
  IconIdBadge2,
  IconIdBadge,
  IconUserCircle,
  IconCreditCard,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "user",
      title: "Users",
      type: "item",
      icon: icons.IconUsers,
      // type: "collapse",
      url: "/user",

    },
    {
      id: "coupon",
      title: " Coupon",
      icon: icons.IconChecklist,
      type: "collapse",
      children: [
        {
          id: "coupon",
          title: "coupon",
          type: "item",
          url: "/coupon",
          breadcrumbs: true,
        },
        {
          id: "add-coupon",
          title: "Add coupon",
          type: "item",
          url: "/add-coupon",
          breadcrumbs: true,
        }
      ],
    },
    {
      // id: "order",
      id: "order",
      title: " Order",
      url: "/orders",
      type: "item",
      icon: icons.IconPackages,
      // type: "collapse",
      // children: [
      //   {
      //     id: "get-order",
      //     title: "All order",
      //     type: "item",
      //     url: "/orders",
      //     breadcrumbs: true,
      //   },
      // ],
    },
  ],
};

export default utilities;
