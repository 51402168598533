import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class Order extends HttpClient {

    constructor() {
        super(baseURL);
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use((config) => {
            config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
            config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
            return config;
        });
    };

    _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (response) => {
                return Promise.resolve(response);
            }
        );
    };

    AllOrderConfig = ApiRoutes.Order.All;

    getAllOrder = async () => {
        return this.instance({
            method: this.AllOrderConfig.Method,
            url: this.AllOrderConfig.Endpoint,
            headers: {},
            data: null,
        });
    };

}

export default Order;
