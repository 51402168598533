import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Enquiry: [],
  AllEarned : [],
};

//internally using immer lib (can create mutable state)
export const enquirySlice = createSlice({
  name: "enquiryData",
  initialState,
  reducers: {
    updateAllEnquiry: (state, action) => {
      state.Enquiry = action.payload;
    },
    updateAllEarned: (state, action) => {
      state.AllEarned = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllEnquiry, updateAllEarned } = enquirySlice.actions;

// this is for configureStore
export default enquirySlice.reducer;
